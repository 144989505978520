import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ComponentDocs = makeShortcode("ComponentDocs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "fixed-side-nav"
    }}>{`Fixed side-nav`}</h2>
    <Row mdxType="Row">
  <Column mdxType="Column">
    <iframe src="https://codesandbox.io/embed/fixed-sidenav-tk9cs?fontsize=14&hidenavigation=1&view=preview" title="fixed-sidenav" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media" style={{
          "width": "100%",
          "height": "500px",
          "border": "0",
          "borderRadius": "4px",
          "overflow": "hidden"
        }} sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin" />
  </Column>
    </Row>
    <h2 {...{
      "id": "fixed-side-nav-with-icons"
    }}>{`Fixed side-nav with icons`}</h2>
    <Row mdxType="Row">
  <Column mdxType="Column">
    <iframe src="https://codesandbox.io/embed/fixed-sidenav-with-icons-875it?fontsize=14&hidenavigation=1&view=preview" title="fixed-sidenav-with-icons" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media" style={{
          "width": "100%",
          "height": "500px",
          "border": "0",
          "borderRadius": "4px",
          "overflow": "hidden"
        }} sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin" />
  </Column>
    </Row>
    <h2 {...{
      "id": "documentation"
    }}>{`Documentation`}</h2>
    <ComponentDocs component="ui-shell" mdxType="ComponentDocs" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      